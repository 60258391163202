import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaFilePdf } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import CurrentPath from "./CurrentPath";
import "./ExamList.css";

const apiUrl = process.env.REACT_APP_API_URL;

function removeMarkdown(markdown) {
  return markdown
      // Remove code blocks
      .replace(/```[\s\S]*?```/g, '')
      // Remove inline code
      .replace(/`[^`]*`/g, '')
      // Remove images
      .replace(/!\[.*?\]\(.*?\)/g, '')
      // Remove links
      .replace(/\[([^\]]+)\]\((.*?)\)/g, '$1')
      // Remove blockquotes
      .replace(/^>\s+/gm, '')
      // Remove headers
      .replace(/^#{1,6}\s+(.*)$/gm, '$1')
      // Remove horizontal rules
      .replace(/^(-{3,}|_{3,}|\*{3,})$/gm, '')
      // Remove bold and italic
      .replace(/(\*\*|__)(.*?)\1/g, '$2')
      .replace(/(\*|_)(.*?)\1/g, '$2')
      // Remove strikethrough
      .replace(/~~(.*?)~~/g, '$1')
      // Remove lists
      .replace(/^\s*([-+*]|(\d+\.))\s+/gm, '')
}

export const downloadPDF = async (exam, index) => {
  const doc = new jsPDF();

  // Initial document setup
  doc.setFont("helvetica", "normal");

  // Logo URL
  const logoUrl = "https://app.photoexamai.com/logo512.png";

  // Load and add the logo in the center
  // The logo size has been adjusted for better quality
  doc.addImage(logoUrl, "PNG", 85, 10, 40, 40, undefined, "FAST");

  // Adjust the Y position after the logo
  let yPosition = 60;

  // Header below the logo
  doc.setFontSize(14);
  doc.setTextColor(40);
  doc.text("Generated by photoexamai.com", 105, yPosition, null, null, "center");
  yPosition += 10; // Space after the header

  doc.setDrawColor(0);
  doc.setLineWidth(0.2);
  doc.line(10, yPosition, 200, yPosition);

  yPosition += 10; // Space after the line

  // Maximum page height
  const pageHeight =
    doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

  // Helper function to add text and manage page addition
  const addText = (
    text,
    xPosition,
    initialYPosition,
    fontSize,
    isTitle = false
  ) => {
    doc.setFontSize(fontSize);
    const lines = doc.splitTextToSize(text, 180); // Adjust the width as needed
    lines.forEach((line, index) => {
      if (initialYPosition > pageHeight - 10) {
        // Check if a new page is needed
        doc.addPage();
        initialYPosition = 20; // Reset the Y position to the top margin of the new page
      }
      if (isTitle && index === 0) {
        doc.setFont("helvetica", "bold");
        doc.setTextColor(60, 60, 60); // A dark gray for the title
      } else {
        doc.setFont("helvetica", "normal");
        doc.setTextColor(40);
      }
      doc.text(line, xPosition, initialYPosition);
      initialYPosition += fontSize * 0.5; // Adjusted line spacing
      if (isTitle && index === 0) {
        initialYPosition += 2; // Additional space after the title
      }
    });
    return initialYPosition;
  };

  // Add the image
  const fetchImage = async (index) => {
    const token = localStorage.getItem("token");

    const url = apiUrl + `/api/easyexams/image?image=` + index;
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (!response.ok) {
        return; // Exit early to avoid further processing the response
      }
      return response.json();
    });
  };

  const responsedata = await fetchImage(index);
  let imageBase64 = responsedata?.image;

  if (imageBase64) {
    // Assuming the image is of type JPEG
    // Get the image dimensions to fit the page margins
    const imgProps = doc.getImageProperties(imageBase64);
    const pdfWidth = doc.internal.pageSize.getWidth() - 20; // PDF width minus margins
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width; // Calculate the height maintaining the aspect ratio

    // Ensure the image is not taller than the page
    if (pdfHeight + yPosition > doc.internal.pageSize.getHeight()) {
      // Adjust the height and calculate the new width
      const maxHeight = doc.internal.pageSize.getHeight() - yPosition - 10; // 10 for the bottom margin
      const newWidth = (imgProps.width * maxHeight) / imgProps.height;
      doc.addImage(imageBase64, "JPEG", 10, yPosition, newWidth, maxHeight);
    } else {
      doc.addImage(imageBase64, "JPEG", 10, yPosition, pdfWidth, pdfHeight);
    }

    yPosition += pdfHeight + 10;
  } else {
    // Add the OCR text
    const formattedOcrText = `Question:\n${formatText(exam?.ocrText)}`;
    yPosition = addText(formattedOcrText, 10, yPosition, 12, true) + 6;
  }

  // Add the response text
  const borrarmarkdown = removeMarkdown(formatText(exam?.response));
  const formattedResponseText = `Result:\n${borrarmarkdown}`;
  addText(formattedResponseText, 10, yPosition, 12, true);

  // Save the PDF with a meaningful filename
  const examDate = new Date(exam?.lastOCR).toISOString().slice(0, 10);
  if (index) {
    doc.save(`Exam-${examDate}-ID${index + 1}.pdf`);
  } else {
    doc.save(`Exam-${examDate}.pdf`);
  }
};

export const formatText = (text) => {
  if (typeof text !== "string") {
    console.error("formatText was called with a non-string argument", text);
    return text; // Return an empty string or handle the error as appropriate
  }
  return text.replace(/\\r\\n|\\r|\\n/g, "\n");
};

const formatTextWithNewlines = (text) => {
  return text.split("\n").map((item, index) => (
    <React.Fragment key={index}>
      {item}
      <br />
    </React.Fragment>
  ));
};

const ExamList = () => {
  const [exams, setExams] = useState([]);
  const [currentExams, setCurrentExams] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const examsPerPage = 10;

  const fetchExams = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${apiUrl}/api/easyexams/exams`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Network response was not ok.");
      const data = await response.json();
      setExams(data.sort((a, b) => new Date(b.lastOCR) - new Date(a.lastOCR)));
      setCurrentPage(1);
    } catch (error) {
      setError("There are no exams in the history.");
      setExams([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExams();
  }, []);

  useEffect(() => {
    const indexOfLastExam = currentPage * examsPerPage;
    const indexOfFirstExam = indexOfLastExam - examsPerPage;
    setCurrentExams(exams.slice(indexOfFirstExam, indexOfLastExam));
  }, [currentPage, exams]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      const targetDate = date.toLocaleDateString("en-CA"); // 'en-CA' uses the format yyyy-mm-dd
      const filteredExams = exams.filter((exam) => {
        const examDate = new Date(exam.lastOCR).toLocaleDateString("en-CA");
        return examDate === targetDate;
      });
      setExams(filteredExams);
      setCurrentPage(1);
    } else {
      fetchExams(); // Reload all exams if date is cleared
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(exams.length / examsPerPage);

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text={"Exam History"} />
      <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
        🖇️ Solved Exam History
      </h1>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="yyyy/MM/dd"
        isClearable
        placeholderText="Select a date"
        className="p-2 w-full rounded bg-white text-black custom-datepicker"
      />
      {loading && <p className="mb-4">Loading...</p>}
      {error && <p className="mb-4 text-black">{error}</p>}
      {!loading &&
        !error &&
        currentExams.map((exam, index) => (
          <div
            key={index}
            className="collapse collapse-plus bg-base-200 rounded-box shadow my-2"
          >
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              Exam "
              {exam.ocrText.split(" ").slice(0, 2).join(" ").toLowerCase()}" -{" "}
              {new Date(exam.lastOCR).toLocaleDateString("en-CA")} -{" "}
              {new Date(exam.lastOCR).toLocaleTimeString("en-CA")}
            </div>
            <div className="collapse-content bg-base-100 text-black p-4">
              <div className="font-semibold">Question:</div>
              <br/>
              <p>{exam.ocrText}</p>
              {/* separator */}
              <hr className="my-4" />
              <div className="font-semibold mt-2">Result:</div>
              <br/>
              <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
                <ReactMarkdown
                  rehypePlugins={[rehypeSanitize]}
                  children={exam?.response}
                  disallowedElements={["pre"]}
                  unwrapDisallowed
                />
              </div>
              <button
                className="btn btn-primary mt-4"
                onClick={() =>
                  downloadPDF(
                    exam,
                    exams.length -
                      1 -
                      exams.findIndex((e) => {
                        console.log(e._id);
                        return e._id === exam._id;
                      })
                  )
                }
              >
                <FaFilePdf className="mr-2" /> Download PDF
              </button>
            </div>
          </div>
        ))}
      <div className="mt-4 flex justify-between">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn btn-primary"
        >
          Previous
        </button>
        <p className="text-lg">
          Page {currentPage} of {totalPages}
        </p>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="btn btn-primary"
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default ExamList;

import {
  faBars,
  faChalkboardTeacher,
  faCheckCircle,
  faEdit,
  faFileAlt,
  faHighlighter,
  faHistory,
  faQuestionCircle,
  faSitemap,
  faStickyNote,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const Header = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const drawerCheckboxRef = useRef(null);

  useEffect(() => {
    // On component load, get the menu state from localStorage
    const savedMenuState = localStorage.getItem("isMenuOpen");
    if (savedMenuState !== null) {
      setIsMenuOpen(JSON.parse(savedMenuState));
    } else {
      setIsMenuOpen(true);
    }
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => {
      const newState = !prevState;
      localStorage.setItem("isMenuOpen", JSON.stringify(newState));
      return newState;
    });
  };

  const closeDrawer = () => {
    if (window.innerWidth < 768) { // If the screen width is less than 768px (md)
      setIsMenuOpen(false);
      localStorage.setItem("isMenuOpen", JSON.stringify(false));
      if (drawerCheckboxRef.current) {
        drawerCheckboxRef.current.checked = false;
      }
    }
  };

  return (
    <div className={`drawer ${isMenuOpen ? "md:drawer-open" : ""}`}>
      <input
        id="my-drawer-2"
        type="checkbox"
        className="drawer-toggle"
        ref={drawerCheckboxRef}
      />
      <div className="drawer-content flex flex-col">
        <div className="navbar bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-md">
          <div className="navbar-start">
            <button
              onClick={toggleMenu}
              className="btn btn-ghost hidden md:flex"
            >
              <FontAwesomeIcon icon={faBars} size="lg" />
            </button>

            <label
              htmlFor="my-drawer-2"
              className="btn btn-ghost drawer-button flex md:hidden"
            >
              <FontAwesomeIcon icon={faBars} size="lg" />
            </label>
            <Link to="/" className="btn btn-ghost text-xl ml-2">
              PhotoExamAI
            </Link>
          </div>
          <div className="navbar-end">
            <Link to="/profile" className="btn btn-ghost">
              <FontAwesomeIcon icon={faUserCircle} size="lg" className="mr-2" />
              My profile
            </Link>
          </div>
        </div>
        <div>{children}</div>
      </div>
      <div className="drawer-side z-50 border-r-2 border-gray-200">
        <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
        <ul className="menu bg-base-100 text-base-content min-h-full w-80 p-4 flex justify-between">
          <div>
            <li className="font-bold text-md mt-4 flex justify-between items-center">
              <Link to="/" onClick={closeDrawer}>Learn with PhotoExamAI 💙</Link>
            </li>
            <div className="divider"></div>
            <li>
              <Link to="/" onClick={closeDrawer}>
                <FontAwesomeIcon icon={faEdit} className="mr-2" width={20} />
                Solve Exam
              </Link>
            </li>
            <li>
              <Link to="/create-diagrams-with-ai" onClick={closeDrawer}>
                <FontAwesomeIcon icon={faSitemap} className="mr-2" width={20} />
                Create Schemas
              </Link>
            </li>
            <li>
              <Link to="/tutors-ai" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  className="mr-2"
                  width={20}
                />
                Consult Tutors
              </Link>
            </li>
            <li>
              <Link to="/interactive-quiz-ai" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mr-2"
                  width={20}
                />
                Take Quiz
              </Link>
            </li>
            <li>
              <Link to="/create-summary-with-ai" onClick={closeDrawer}>
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" width={20} />
                Summarize Content
              </Link>
            </li>
            <li>
              <Link to="/highlight-text-with-ai" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faHighlighter}
                  className="mr-2"
                  width={20}
                />
                Highlight Text
              </Link>
            </li>
            <li>
              <Link to="/correct-exam-with-ai" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="mr-2"
                  width={20}
                />
                Correct Exam
              </Link>
            </li>
            <li>
              <Link to="/note-taking-with-ai" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faStickyNote}
                  className="mr-2"
                  width={20}
                />
                Note Taking
              </Link>
            </li>
          </div>
          <div>
            <div className="divider"></div>

            <li>
              <Link to="/history" onClick={closeDrawer}>
                <FontAwesomeIcon icon={faHistory} className="mr-2" width={20} />
                View History
              </Link>
            </li>
            <li>
              <Link to="https://photoexamai.com/faq.html" target="_blank" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mr-2"
                  width={20}
                />
                FAQ
              </Link>
            </li>
            <li>
              <Link to="/profile" onClick={closeDrawer}>
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="mr-2"
                  width={20}
                />
                My Profile
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Header;
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function useAuth() {
  const [token, setToken] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Obtener el token de la URL
    const params = new URLSearchParams(location.search);
    const urlToken = params.get("token");

    if (urlToken) {
      // Guardar el token en el localStorage y estado
      localStorage.setItem("token", urlToken);
      setToken(urlToken);

      // Eliminar el token de la URL
      params.delete("token");
      navigate({ search: params.toString() }, { replace: true });
    } else {
      // Si no hay token en la URL, buscar en el localStorage
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        setToken(storedToken);
      } else setToken(null);
    }
  }, [location, navigate]);

  // Función para limpiar el token del localStorage y estado
  const clearToken = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  return [token, clearToken];
}

export default useAuth;

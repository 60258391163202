import { useEffect, useState } from 'react';

const useCheckSubscription = () => {
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkSubscription = async (token) => {
      try {
        const subscriptionResponse = await fetch(
          `${apiUrl}/api/easyexams/checkpremium`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (subscriptionResponse.ok) {
          try {
            const jsonResponse = await subscriptionResponse.json();
            setSubscriptionType(jsonResponse.subscriptionType);
          } catch (error) {
            console.error('Error parsing JSON response:', error);
            setError('Error parsing JSON response');
          }
        } else {
          setError('Subscription check failed');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Network error');
      } finally {
        setLoading(false);
      }
    };

    const token = localStorage.getItem('token');
    if (token) {
      checkSubscription(token);
    } else {
      setLoading(false);
    }
  }, [apiUrl]);

  return { subscriptionType, loading, error };
};

export default useCheckSubscription;

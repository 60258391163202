import React, { useEffect, useState } from "react";
import { FaStripe, FaUserCircle } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import useAuth from "./useAuthToken";

const apiUrl = process.env.REACT_APP_API_URL;

const UserProfile = ({ setShowNoTokenModal }) => {
  const [user, setUser] = useState({
    email: "",
    dateSubscriptionFinish: 5,
    stripeLink: "",
    subscriptionType: "",
  });
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [token, clearToken] = useAuth();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    window.location.href = "https://www.photoexamai.com/?logout=true";
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (token === "" ) return;
      if (!token) {
        setShowNoTokenModal(true);
        return;
      }
      const response = await fetch(apiUrl + "/api/easyexams/checktoken", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Use the token in the request
        },
      });

      const dataToken = await response.json();
      let userData = {};
      if (dataToken?.email) localStorage.setItem("email", dataToken.email);

      if ("dateSubscriptionFinish" in dataToken) {
        userData = {
          email: dataToken.email,
          dateSubscriptionFinish: dataToken.dateSubscriptionFinish,
          stripeLink: "https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII",
        };
      } else {
        userData = {
          email: dataToken.email,
          dateSubscriptionFinish: "",
          stripeLink: "https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII",
        };
      }

      try {
        const subscriptionResponse = await fetch(
          `${apiUrl}/api/easyexams/checkpremium`,
          {
            method: "POST", // Assuming this endpoint is POST
            headers: {
              Authorization: `Bearer ${token}`, // Use the token in the request
            },
          }
        );

        if (
          subscriptionResponse.ok &&
          subscriptionResponse.headers
            .get("Content-Type")
            ?.includes("application/json")
        ) {
          try {
            const jsonResponse = await subscriptionResponse.json();
            const subscriptionType = jsonResponse.subscriptionType; // Access the subscription type this way
            userData.subscriptionType = subscriptionType;
          } catch (error) {
            console.error("Error parsing JSON response:", error);
          }
        } else {
          console.error(
            "Response is not JSON or the server returned an error:",
            subscriptionResponse.status,
            await subscriptionResponse.text()
          );
        }

        setUser(userData);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserData();
  }, [token, setShowNoTokenModal]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <div className="container mx-auto p-5 mb-20 mt-20">
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="px-4 py-5 sm:p-6 bg-gradient-to-r from-blue-500 to-indigo-600">
          <h3 className="text-lg leading-6 font-medium text-white">
            <FaUserCircle className="inline-block h-6 w-6 mr-2" />
            User Profile
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Email</dt>
              <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                {user.email}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Data</dt>
              <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                <Link to="/welcome" className="btn btn-neutral w-full">
                  Edit My Data
                </Link>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">What do you think of PhotoExam?</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <button
                  className="btn btn-accent w-full"
                  onClick={() =>
                    document.getElementById("my_modal_3").showModal()
                  }
                >
                  ⭐️ Leave Us Your Feedback
                </button>
              </dd>
            </div>
            <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Subscription Type</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.subscriptionType || "Not available"}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Subscription End</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.dateSubscriptionFinish}
              </dd>
            </div>
            <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Subscription Management</dt>
              <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0 flex flex-col ">
                <a
                  href="https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII" // Replace with the real link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <FaStripe className="mr-2" />
                  Manage My Subscription on Stripe
                </a>
                <p className="max-w-2xl mt-2">
                  Does PhotoExam not meet your needs? Remember, we have
                  <a
                    href="https://www.fotoexamen.com/precios.html"
                    title="Prices"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    other subscription plans{" "}
                  </a>{" "}
                  with more powerful AI models that can help with more complex problems.
                </p>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Logout</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <button className="btn btn-neutral" onClick={handleLogout}>
                  Log Out
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
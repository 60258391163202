import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "./useAuthToken";
const apiUrl = process.env.REACT_APP_API_URL;

export default function Welcome({ setShowNoTokenModal }) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nombre: "",
    edad: "",
    estudios: "",
    ciudad: "",
    dedicacion: "",
    otroDedicacion: "",
    encontro: "",
    otroEncontro: "",
    atencion: "",
    otroAtencion: "",
    simpatica: 3,
    directa: 3,
    empatica: 3,
    critica: 3,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const debounceTimeout = useRef(null);
  const [token, clearToken] = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) {
        return;
      }

      fetch(apiUrl + "/api/easyexams/getprofile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setFormData(data?.data);
          setIsFirstLoad(false);
        })
        .catch((error) => {
          alert("Error retrieving your data. Please reload the page.");
        });
    };
    fetchUserData();
  }, [token, setShowNoTokenModal]);

  useEffect(() => {
    if (isFirstLoad || !token) {
      return;
    }

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      const forms = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        forms.append(key, value);
      }

      console.log("Sending data:", Object.fromEntries(forms.entries())); // <-- Añadir esto

      fetch(apiUrl + "/api/easyexams/saveprofile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: forms,
        method: "POST",
      })
        .then((res) => res.json())
        .then((data) => console.log(data))
        .catch((error) => {
          alert("Error saving your data. Please try again.");
        });
    }, 1000);
  }, [formData, token, isFirstLoad]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSliderChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const nextStep = () => {
    if (!token) {
      setShowNoTokenModal(true);
      return;
    }
    // Validations for each step
    if (step === 1) {
      if (!formData.nombre || !formData.edad || !formData.estudios || !formData.ciudad) {
        alert("Please fill in all required fields.");
        return;
      }
    } else if (step === 2) {
      if (!formData.dedicacion || !formData.encontro || !formData.atencion) {
        alert("Please fill in all required fields.");
        return;
      }
      if ((formData.dedicacion === "Otros" || formData.dedicacion === "Otros Estudios") && !formData.otroDedicacion) {
        alert("Please specify your occupation.");
        return;
      }
      if (formData.encontro === "Otros" && !formData.otroEncontro) {
        alert("Please specify how you found us.");
        return;
      }
      if (formData.atencion === "Otros" && !formData.otroAtencion) {
        alert("Please specify what interests you most about the application.");
        return;
      }
    }
    
    setStep((prevStep) => prevStep + 1);
    if (step + 1 === 5) {
      window.location.href = "/";
    }
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <div className="container mx-auto p-4 my-8 md:p-16">
      <div className="flex justify-center mb-6">
        <div className="steps">
          <div
            data-content="📊"
            className={`step ${step >= 1 ? "step-neutral" : ""}`}
          >
            Data
          </div>
          <div
            data-content="🔍"
            className={`step ${step >= 2 ? "step-neutral" : ""}`}
          >
            More Info
          </div>
          <div
            data-content="🤖"
            className={`step ${step >= 3 ? "step-neutral" : ""}`}
          >
            AI
          </div>
          <div
            data-content="🚀"
            className={`step ${step >= 4 ? "step-neutral" : ""}`}
          >
            START NOW!
          </div>
        </div>
      </div>

      {step === 1 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-1"
        >
          <h2 className="text-2xl font-bold mb-4">
            {" "}
            Welcome to PhotoExam!{" "}
          </h2>
          <p className="mb-4">
            To improve your experience with our advanced AI and make your exam results better and more accurate, please provide the following necessary information:
          </p>
          <form className="space-y-4">
            <div>
              <label className="block text-lg font-medium">Name*</label>
              <input
                type="text"
                name="nombre"
                className="input input-bordered w-full"
                value={formData.nombre}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-lg font-medium">Age*</label>
              <input
                type="number"
                name="edad"
                className="input input-bordered w-full"
                value={formData.edad}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-lg font-medium">Studies*</label>
              <input
                type="text"
                name="estudios"
                className="input input-bordered w-full"
                value={formData.estudios}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-lg font-medium">City*</label>
              <input
                type="text"
                name="ciudad"
                className="input input-bordered w-full"
                value={formData.ciudad}
                onChange={handleChange}
              />
            </div>
          </form>
          <button
            className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white mt-4"
            onClick={nextStep}
          >
            Next
          </button>
        </motion.div>
      )}

      {step === 2 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-2"
        >
          <h2 className="text-2xl font-bold mb-4">More Information</h2>
          <p className="mb-4">This helps us improve:</p>
          <form className="space-y-4">
            <div>
              <label className="block text-lg font-medium">
                What is your occupation?*
              </label>
              <select
                name="dedicacion"
                className="select select-bordered w-full"
                value={formData.dedicacion}
                onChange={handleChange}
              >
                <option value="">Select an option</option>
                <option value="Padre o familiar">Padre o familiar de un estudiante</option>
                <option value="Docente">Docente</option>
                <optgroup label="Estudiante">
                  <option value="Master">Estudiante de máster</option>
                  <option value="Universidad">Estudiante universitario</option>
                  <option value="Formación Profesional">
                    Estudiante de Formación Profesional
                  </option>
                  <option value="Bachillerato">Estudiante de bachillerato</option>
                  <option value="Primaria">Estudiante de primaria</option>
                  <option value="Otros Estudios">Otros estudios</option>
                </optgroup>
                <option value="Otros">Otros</option>
              </select>
            </div>
            {(formData.dedicacion === "Otros" ||
              formData.dedicacion === "Otros Estudios") && (
              <div className="mt-4">
                <label className="block text-lg font-medium">
                  Please specify*
                </label>
                <input
                  type="text"
                  name="otroDedicacion"
                  className="input input-bordered w-full"
                  value={formData.otroDedicacion}
                  onChange={handleChange}
                  placeholder="Specify your occupation"
                />
              </div>
            )}

            <div>
              <label className="block text-lg font-medium">
                How did you find us?*
              </label>
              <select
                name="encontro"
                className="select select-bordered w-full"
                value={formData.encontro}
                onChange={handleChange}
              >
                <option value="">Select an option</option>
                <option value="Recomendación de un amigo">
                  Recomendación de un amigo
                </option>
                <option value="Búsqueda en internet">
                  Búsqueda en internet (Google, Bing, etc.)
                </option>
                <optgroup label="Redes sociales">
                  <option value="Instagram">Instagram</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Tiktok">Tiktok</option>
                  <option value="Youtube">Youtube</option>
                </optgroup>
                <option value="Otros">Otros medios</option>
              </select>
            </div>

            {formData.encontro === "Otros" && (
              <div className="mt-4">
                <label className="block text-lg font-medium">
                  Please specify*
                </label>
                <input
                  type="text"
                  name="otroEncontro"
                  className="input input-bordered w-full"
                  value={formData.otroEncontro}
                  onChange={handleChange}
                  placeholder="Specify how you found us"
                />
              </div>
            )}

            <div>
              <label className="block text-lg font-medium">
                What tool interests you the most?*
              </label>
              <select
                name="atencion"
                className="select select-bordered w-full"
                value={formData.atencion}
                onChange={handleChange}
              >
                <option value="">Select an option</option>
                <option value="Exam">Solve exams</option>
                <option value="Outline">Create outlines</option>
                <option value="Tutor">Chat with tutors</option>
                <option value="Quiz">Create a quiz</option>
                <option value="Summary">Summarize text</option>
                <option value="Highlight">Highlight text</option>
                <option value="Correct">Correct exams</option>
                <option value="Notes">Take notes</option>
                <option value="Others">Other features</option>
              </select>
            </div>
            {formData.atencion === "Others" && (
              <div className="mt-4">
                <label className="block text-lg font-medium">
                  Please specify*
                </label>
                <input
                  type="text"
                  name="otroAtencion"
                  className="input input-bordered w-full"
                  value={formData.otroAtencion}
                  onChange={handleChange}
                  placeholder="Specify what interests you most about the application"
                />
              </div>
            )}
          </form>
          <div className="flex justify-between mt-4">
            <button
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              onClick={prevStep}
            >
              Previous
            </button>
            <button
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              onClick={nextStep}
            >
              Next
            </button>
          </div>
        </motion.div>
      )}

      {step === 3 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-3"
        >
          <h2 className="text-2xl font-bold mb-4">Customize Your AI</h2>
          <p className="mb-4">
            Currently under development, you may not notice much personality and difference in the responses by adjusting these parameters, but you can tell us how you want your ideal AI to be:
          </p>
          <form className="space-y-4">
            <div>
              <label className="block text-lg font-medium">Friendly</label>
              <input
                type="range"
                name="simpatica"
                min="1"
                max="5"
                value={formData.simpatica}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>1 (Not friendly)</span>
                <span>2</span>
                <span>3 (Usually friendly)</span>
                <span>4</span>
                <span>5 (Very friendly)</span>
              </div>
            </div>
            <div>
              <label className="block text-lg font-medium">Direct</label>
              <input
                type="range"
                name="directa"
                min="1"
                max="5"
                value={formData.directa}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>1 (Not direct)</span>
                <span>2</span>
                <span>3 (Usually direct)</span>
                <span>4</span>
                <span>5 (Very direct)</span>
              </div>
            </div>
            <div>
              <label className="block text-lg font-medium">Empathetic</label>
              <input
                type="range"
                name="empatica"
                min="1"
                max="5"
                value={formData.empatica}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>1 (Not empathetic)</span>
                <span>2</span>
                <span>3 (Usually empathetic)</span>
                <span>4</span>
                <span>5 (Very empathetic)</span>
              </div>
            </div>
            <div>
              <label className="block text-lg font-medium">Critical</label>
              <input
                type="range"
                name="critica"
                min="1"
                max="5"
                value={formData.critica}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>1 (Not critical)</span>
                <span>2</span>
                <span>3 (Usually critical)</span>
                <span>4</span>
                <span>5 (Very critical)</span>
              </div>
            </div>
          </form>
          <div className="flex justify-between mt-4">
            <button
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              onClick={prevStep}
            >
              Previous
            </button>
            <button
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              onClick={nextStep}
            >
              Next
            </button>
          </div>
        </motion.div>
      )}

      {step === 4 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-5"
        >
          <h2 className="text-2xl font-bold mb-4">
            What are the advantages of PhotoExam?
          </h2>
          <p className="mb-4">
            Discover the benefits of using our AI to improve your grades:
          </p>
          <div className="bg-base-200 p-6 rounded-lg mb-4 shadow-md">
            <ul className="list-disc list-inside space-y-2">
              <li className="p-2 rounded-lg">
                <strong>Solve exams:</strong> Use tasks, exams, or exercises from previous years to prepare for your upcoming exams.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Create outlines:</strong> Use your syllabus to create your own outlines and study effectively.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Chat with AI tutors:</strong> Tutors available in mathematics, biology, physics... to answer your questions.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Create a personalized tutor from your notes:</strong> If you have questions about your notes, upload your syllabus and ask any question as if it were your teacher.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Generate interactive quizzes:</strong> Create quizzes with various types of questions to test your skills or learn in a fun way.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Take notes:</strong> Upload an audio of your class and get notes automatically.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Summarize, highlight, and correct:</strong> Among many other things.
              </li>
            </ul>
          </div>
          <p className="mb-4 font-bold text-lg">
            DON'T WASTE TIME AND START IMPROVING YOUR GRADES NOW 🔟.
          </p>
          <div className="flex justify-between mt-4">
            <button
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              onClick={prevStep}
            >
              Previous
            </button>
            <Link
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              to="/"
            >
              Finish
            </Link>
          </div>
        </motion.div>
      )}

      <p className="mt-4 text-sm">
        You can modify all the data at any time from "Your Profile".
      </p>
    </div>
  );
}

import React from 'react'

export default function ModalNoToken({ setShowNoTokenModal }) {
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold">Oops!</p>
          <div
            className="cursor-pointer z-50"
            onClick={() => setShowNoTokenModal(false)}
          >
            <svg
              className="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M12.3 11.7l-3.3-3.3 3.3-3.3L11.7 5l-3.3 3.3L5 5 4.3 5.7l3.3 3.3-3.3 3.3L5 12.3l3.3-3.3 3.3 3.3z" />
            </svg>
          </div>
        </div>
        <p>
          Log in at{" "}
          <a href="https://www.photoexamai.com" title="Log in">
            {" "}
            www.photoexamai.com{" "}
          </a>{" "}
          (in the navigation bar) and get one of our plans to use the tool.
        </p>
        <div className="flex justify-end pt-2">
          <button
            className="px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
            onClick={() =>
              (window.location.href = "https://www.photoexamai.com")
            }
          >
            Home
          </button>
        </div>
      </div>
    </div>
  )
}

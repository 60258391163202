import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useEffect, useRef, useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { Link, useLocation } from "react-router-dom";
import rehypeSanitize from "rehype-sanitize";
import { io } from "socket.io-client";
import CurrentPath from "./CurrentPath.js";
import { downloadPDF } from "./ExamList.js";
import useAuth from "./useAuthToken.js";
import useCheckSubscription from "./useCheckSubscription.js";

const apiUrl = process.env.REACT_APP_API_URL;

const ExamCorrector = ({ setShowNoTokenModal }) => {
  const [image, setImage] = useState(null);
  const [imagebin, setImagebin] = useState(null);
  const [ocrText, setOcrText] = useState("");
  const [examResult, setExamResult] = useState("");
  const [isStripeModalVisible, setIsStripeModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [examData, setExamData] = useState(null);
  const [isLoadingOcr, setIsLoadingOcr] = useState(false);
  const [showTipsModal, setShowTipsModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);
  const { subscriptionType, loading, error } = useCheckSubscription();
  const [token, clearToken] = useAuth();

  const location = useLocation();
  const stripeModalRef = useRef(null);
  const socketRef = useRef(null);
  const messageCount = useRef(0);

  const connectSocket = (onFormResponse, onGenerationComplete) => {
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("Socket connected.");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected.");
    });

    socket.on("form_response", (data) => {
      onFormResponse(data);
    });

    socket.on("form_end", (data) => {
      onGenerationComplete(data);
      socket.disconnect();
    });

    return socket;
  };

  useEffect(() => {
    setExamData({
      ocrText: ocrText,
      response: examResult,
      lastOCR: new Date(),
    });
  }, [ocrText, examResult]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        stripeModalRef.current &&
        !stripeModalRef.current.contains(event.target)
      ) {
        setIsStripeModalVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleImageChange = async (e) => {
    if (subscriptionType === "No Subscription") {
      alert(
        "You need to subscribe to use Exam Correction."
      );
      setIsLoading(false);
      return;
    }

    setIsLoadingOcr(true);
    const selectedFile = e.target.files[0];

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoadingOcr(false);
      return;
    }

    try {
      if (selectedFile.type === "application/pdf") {
        setImage(null);
        setImagebin(null);
        setPdfUrl(selectedFile);

        const pdf = await pdfjsLib.getDocument(
          URL.createObjectURL(selectedFile)
        ).promise;

        if (pdf.numPages > 45) {
          setIsLoading(false);
          setIsLoadingOcr(false);
          alert(
            "Your exam PDF must have less than 45 pages as it is too large to process."
          );
          return;
        }

        let textContent = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const text = await page.getTextContent();
          text.items.forEach((item) => {
            textContent += item.str + " ";
          });
        }
        setOcrText(textContent);
        setPdfPreview(URL.createObjectURL(selectedFile));
        setIsLoadingOcr(false);
      } else if (selectedFile.type.startsWith("image/")) {
        setPdfUrl(null);
        setPdfPreview(null);
        setImage(URL.createObjectURL(selectedFile));
        setImagebin(selectedFile);

        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await fetch(apiUrl + "/api/easyexams/ocr", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.nowpay) {
            window.location.href = "https://www.fotoexamen.com/precios.html";
          } else {
            setIsLoadingOcr(false);
            setOcrText(responseData.texts[0]);
          }
        } else {
          setIsLoadingOcr(false);
          alert(
            "Could not detect text in the image. Make sure your image is smaller than 20 MB and has one of the following formats: .png, .jpeg, .gif, or .webp."
          );
        }
      } else {
        alert("File type not supported. Upload an image or a PDF file.");
        setIsLoadingOcr(false);
      }
    } catch (error) {
      console.error("Error processing the file:", error);
      setIsLoadingOcr(false);
    }
  };

  const waitForSocketConnection = (socket) => {
    return new Promise((resolve, reject) => {
      const maxAttempts = 5;
      let attempts = 0;

      const checkSocketConnection = () => {
        if (socket.connected) {
          resolve();
        } else {
          attempts += 1;
          if (attempts >= maxAttempts) {
            reject(new Error("Socket connection failed"));
          } else {
            setTimeout(checkSocketConnection, 200); // Retry after 200ms
          }
        }
      };

      checkSocketConnection();
    });
  };

  const handleCorrectExam = async () => {
    setIsLoading(true);
    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoading(false);
      return;
    }

    socketRef.current = connectSocket(
      (data) => {
        messageCount.current += 1;
        if (messageCount.current % 100 === 0) {
          setExamResult(data?.form_response);
        }
      },
      (data) => {
        setExamResult(data?.form_end);
        setIsLoading(false);
      }
    );

    try {
      await waitForSocketConnection(socketRef.current);
    } catch (error) {
      alert(
        "Could not establish the connection. Please try again."
      );
      setIsLoading(false);
      return;
    }

    const data = {
      token: token,
      form: {
        ocrText: ocrText,
      },
      files: {},
    };

    if (imagebin) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1]; // Get the base64 string without the prefix
        data.files.image = base64Data;
        socketRef.current.emit("form_correct", data);
      };
      reader.readAsDataURL(imagebin);
    } else if (pdfUrl) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1]; // Get the base64 string without the prefix
        data.files.pdf = base64Data;
        socketRef.current.emit("form_correct", data);
      };
      reader.readAsDataURL(pdfUrl);
    } else {
      socketRef.current.emit("form_correct", data);
    }
  };

  const showStripeModal = () => {
    setIsStripeModalVisible(true);
  };

  const toggleTipsModal = () => {
    setShowTipsModal(!showTipsModal);
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Correct Your Exam with AI</h2>
        <p className="mb-4 text-gray-600">
          Simplify and improve the exam correction process with our innovative artificial intelligence. Upload an image or a PDF of your completed exam and let our custom AI correct it for you. Edit any detected text errors and press "correct exam" when you're ready. Get accurate results and download the corrected exam in PDF format.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Upload images or PDF documents of completed exams</li>
          <li className="mb-2">Accurate and fast AI correction</li>
          <li className="mb-2">Option to edit text before final correction</li>
          <li className="mb-2">Results available for viewing and downloading in PDF</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">How It Works</h2>
        <p className="mb-4 text-gray-600">
          Using our AI exam correction system is simple and effective. Upload an image or a PDF document of your completed exam. The AI will automatically analyze and correct the content. You can review and edit any detected errors before confirming the final correction. Once ready, you can view the results and download the corrected exam in PDF format.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Upload your exam in image or PDF format</li>
          <li className="mb-2">Let the AI analyze and correct the content</li>
          <li className="mb-2">Edit the text if necessary</li>
          <li className="mb-2">Get accurate results and download them in PDF</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Advantages of AI Correction</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Time-saving in exam correction</li>
          <li className="mb-2">Accuracy and consistency in corrections</li>
          <li className="mb-2">Reduction of human errors</li>
          <li className="mb-2">Ease of editing and adjusting corrections</li>
          <li className="mb-2">Fast and accessible results in PDF format</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Applications of AI Exam Corrector</h2>
        <p className="mb-4 text-gray-600">
          Our AI exam corrector is a versatile tool, useful in various educational and professional contexts. Whether you're a student, teacher, or professional needing to verify answers in documents, this tool provides an efficient and accurate solution for exam correction.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Students who want to verify their answers</li>
          <li className="mb-2">Teachers who need to quickly correct exams</li>
          <li className="mb-2">Professionals who need to review documents with precision</li>
          <li className="mb-2">Anyone who needs fast and accurate correction</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Improve Your Correction Process with AI</h2>
        <p className="text-gray-600">
          Our AI exam corrector is designed to transform the way you correct and review exams. Forget the tedious manual process and take advantage of the precision and speed of our artificial intelligence. Upload your completed exam, edit any detected errors, and get exact results downloadable in PDF. Start using our AI exam corrector today and improve your efficiency in exam correction!
        </p>
      </section>
    </div>
  );
  
  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text="Correct your exam" />
      <div className="container max-w-4xl mx-auto p-8 bg-white rounded">
        <div className="mb-8 text-center max-w-xl mx-auto">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
            ✍🏻 Correct your exam
          </h1>
          <p>
            Use our custom artificial intelligence to correct all your exams. Upload an image or a PDF of your completed exam. Edit the detected text if you find any errors and press correct exam when you are ready. You can view the result or download it in PDF format.
          </p>
        </div>

        <div className="mb-8 space-y-6">
          <h2 className="text-2xl font-bold mb-4">Upload your completed exam:</h2>
          <div className="flex justify-center items-center">
            <input
              type="file"
              accept="image/*,application/pdf"
              capture="environment"
              className="hidden"
              onChange={handleImageChange}
              id="photo-capture"
            />
            <label
              htmlFor="photo-capture"
              className="btn btn-primary flex justify-center items-center bg-green-200 hover:bg-green-300 text-green-700 font-semibold py-3 px-6 rounded cursor-pointer transition ease-in-out duration-300"
            >
              <FontAwesomeIcon icon={faCamera} className="fa-lg mr-2" />
              Take Photo
            </label>
          </div>

          <div className="flex justify-center items-center">
            <input
              type="file"
              accept="image/*,application/pdf"
              className="file-input w-full max-w-xs"
              onChange={handleImageChange}
            />
          </div>
        </div>

        {(image || pdfPreview) && (
          <div className="flex items-center justify-center mb-16">
            <dialog id="previewModal" className="modal">
              <div className="modal-box">
                {image && (
                  <img
                    src={image}
                    alt="Uploaded exam"
                    className="max-w-full h-auto"
                  />
                )}
                {pdfPreview && (
                  <iframe
                    src={pdfPreview}
                    title="PDF Preview"
                    className="w-full h-96"
                  ></iframe>
                )}
                <div className="modal-action">
                  <button
                    className="btn"
                    onClick={() =>
                      document.getElementById("previewModal").close()
                    }
                  >
                    Close
                  </button>
                </div>
              </div>
            </dialog>
            <div
              onClick={() =>
                document.getElementById("previewModal").showModal()
              }
              className="btn mt-3 rounded h-64 w-full object-cover object-top flex items-center justify-center overflow-hidden"
              style={{ background: "#f0f0f0" }}
            >
              {image ? (
                <img
                  src={image}
                  alt="Uploaded exam"
                  className="max-w-full h-auto"
                />
              ) : (
                <FaFilePdf size={64} />
              )}
            </div>
          </div>
        )}

        {subscriptionType !== "Pro" && ocrText !== "" && (
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Detected Text:</h2>
            <textarea
              className="w-full h-64 border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line"
              value={ocrText}
              onChange={(e) => setOcrText(e.target.value)}
            ></textarea>
          </div>
        )}

        {isLoadingOcr && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">
              Detecting text from the file or image...
            </p>
          </div>
        )}
        {isLoading && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">
              Correcting exam with our advanced AI...
            </p>
          </div>
        )}
        <div className="mb-8">
          <div className="flex justify-start items-center space-x-4 flex-col md:flex-row">
            <button
              className={`btn btn-primary flex my-4 justify-center items-center bg-green-200 hover:bg-green-300 text-green-700 font-semibold py-3 px-6 rounded transition ease-in-out duration-300 ${
                !ocrText.trim() ||
                (isLoading && "opacity-50 cursor-not-allowed")
              }`}
              onClick={handleCorrectExam}
              disabled={!ocrText.trim() || isLoading}
            >
              Correct Exam
            </button>
            <button
              onClick={toggleTipsModal}
              className="btn font-bold py-1 px-3 text-sm rounded ml-2"
            >
              Usage Tips
            </button>
          </div>

          {examResult !== "" && (
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4">
                Correction Result:
              </h2>
              <button
                onClick={() => downloadPDF(examData)}
                className="mb-2 bg-red-200 hover:bg-red-300 text-red-800 font-semibold py-2 px-4 rounded transition ease-in-out duration-300 flex items-center justify-center mt-4"
              >
                <FaFilePdf size={24} className="mr-2" /> Download PDF
              </button>
              <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
                <ReactMarkdown
                  rehypePlugins={[rehypeSanitize]}
                  children={examResult}
                  disallowedElements={["pre"]}
                  unwrapDisallowed
                />
              </div>
            </div>
          )}
        </div>
        {isStripeModalVisible && (
          <div
            id="stripeModal"
            ref={stripeModalRef}
            className="modal fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4"
          >
            <div className="modal-content bg-white p-2 sm:p-6 rounded-lg shadow-lg text-center overflow-auto max-h-full">
              <div className="modal fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="modal-content bg-white p-2 sm:p-6 rounded-lg shadow-lg text-center overflow-auto max-h-full">
                  <h2 className="text-lg sm:text-xl font-bold mb-4">
                    You've used up your free credits
                  </h2>
                  <p className="mb-4 text-sm sm:text-base">
                    Want to continue using Exam Correction? Discover the amazing benefits of our premium plans:
                  </p>
                  <ul className="list-disc list-inside text-left mb-4 text-sm sm:text-base">
                    <li className="mb-2">
                      🚀 Unlimited access to exam corrections.
                    </li>
                    <li className="mb-2">
                      💡 Priority support to help you when you need it most.
                    </li>
                    <li className="mb-2">
                      🔐 Priority processing of your requests.
                    </li>
                    <li className="mb-2">
                      🎓 Advanced tools for more effective learning.
                    </li>
                    <li className="mb-2">
                      💼 Additional resources to prepare for your exams.
                    </li>
                  </ul>
                  <p className="mb-4 font-bold text-sm sm:text-base">
                    Invest in your academic success, choose a premium plan today and feel the difference!
                  </p>
                  <button
                    id="view-prices"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded transition duration-300 ease-in-out shadow-lg transform hover:scale-105"
                    onClick={showStripeModal}
                  >
                    View Plans 💲
                  </button>
                </div>
              </div>

              <button onClick={showStripeModal}>View Plans 💲</button>
            </div>
          </div>
        )}

        {showTipsModal && (
          <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div className="relative p-8 bg-white w-full max-w-lg m-auto flex-col flex rounded-lg shadow-lg">
              <div className="flex justify-between items-center pb-3">
                <p className="text-2xl font-bold">
                  Tips for Using the Platform
                </p>
                <div className="cursor-pointer z-50" onClick={toggleTipsModal}>
                  <svg
                    className="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path d="M12.3 11.7l-3.3-3.3 3.3-3.3L11.7 5l-3.3 3.3L5 5 4.3 5.7l3.3 3.3-3.3 3.3L5 12.3l3.3-3.3 3.3 3.3z" />
                  </svg>
                </div>
              </div>
              <ul className="list-disc list-inside text-left text-md lg:text-lg mb-4 leading-relaxed">
                <li>
                  Clear photography: Make sure to take clear photos where the text is completely horizontal. This improves the accuracy with which the platform can interpret and process the information. Also, the image must not exceed 20MB and the accepted formats are: 'png', 'jpeg', 'gif', 'webp'
                </li>
                <li>
                  Repeat the process: Do not hesitate to correct the same photo several times. Sometimes the platform can offer slightly different results, so it is useful to perform several tests to get the best answer.
                </li>
                <li>
                  Reasoning in mathematics: In math exams, it is crucial not only to rely on automatic corrections. Take the reasoning offered by the platform and then verify the calculations manually to make sure they are correct.
                </li>
                <li>
                  Use the{" "}
                  <Link
                    to="/tutors"
                    className="text-blue-600 hover:text-blue-800 underline hover:underline decoration-blue-500 hover:decoration-blue-700 transition duration-300 ease-in-out"
                  >
                    tutors
                  </Link>
                  : If you have questions about any answer, take advantage of the tutors section on the platform. You can upload the exercise in question and ask all the questions you need to clarify your doubts and better understand the material.
                </li>
              </ul>
              <div className="flex justify-end pt-2">
                <button
                  className="px-4 bg-blue-500 hover:bg-blue-700 text-white p-3 rounded-lg text-sm lg:text-base transition duration-300 ease-in-out"
                  onClick={toggleTipsModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {!token && <SEOText />}
    </div>
  );
};

export default ExamCorrector;

import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useState } from "react";
import CurrentPath from "./CurrentPath";
import useAuth from "./useAuthToken";
import useCheckSubscription from "./useCheckSubscription";
const apiUrl = process.env.REACT_APP_API_URL;

const Highlight = ({ setShowNoTokenModal }) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { subscriptionType, loading, error } = useCheckSubscription();
  const [token, clearToken] = useAuth();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setText(""); // Clear the text to hide the text area
    setHighlightedText("");
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setFile(null); // Clear the file to hide the file input
    setHighlightedText("");
  };

  const handleHighlight = async () => {
    if (!file && !text.trim()) {
      alert("Please upload a file or enter some text before highlighting.");
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      return;
    }

    if (subscriptionType === "No Subscription") {
      alert("You need a subscription to use the service.");
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    let pdftext = "";

    if (file) {
      if (file.type === "application/pdf") {
        try {
          const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file))
            .promise;
          let textContent = "";
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const text = await page.getTextContent();
            text.items.forEach((item) => {
              textContent += item.str + " ";
            });
          }
          pdftext = textContent;
          formData.append("text", pdftext);
        } catch (error) {
          console.error("Error extracting text from PDF:", error);
          setErrorMessage(
            "Error extracting text from PDF. Please try another file."
          );
          setIsLoading(false);
          return;
        }
      } else if (file.type.startsWith("image/")) {
        formData.append("files", file);
      } else {
        alert("Unsupported file type. Upload an image or PDF file.");
        setIsLoading(false);
        return;
      }
    }

    if (text) {
      formData.append("text", text);
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/highlight`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (data?.too_long) {
        setErrorMessage(
          "The text is too long. Please upload a smaller file or enter less text. You have uploaded: " +
            data?.total_tokens +
            " tokens, and the maximum is " +
            data?.max_tokens +
            " tokens."
        );
        return;
      }

      if (!response.ok) {
        throw new Error(data.message || "Error fetching highlighted text.");
      }

      // unescape " characters that were written like \"
      data.highlight = data.highlight.replace(/\\\"/g, '"');

      setHighlightedText(data.highlight);
    } catch (error) {
      console.error("Error highlighting text:", error);
      setErrorMessage(
        "Error. Please try again or with a smaller file. If the problem persists, contact us."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">AI Text Highlighter</h2>
        <p className="mb-4 text-gray-600">
          Simplify your study process with our AI Text Highlighter. Upload a PDF document, multiple images, or enter text directly to receive automated highlighting that helps you quickly identify main ideas, important data, definitions, and key dates. This tool is designed for students and professionals looking to optimize their time and improve their understanding of the material.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Compatible with PDFs, images, and direct text</li>
          <li className="mb-2">Automated and efficient highlighting</li>
          <li className="mb-2">Ideal for improving study and review techniques</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Smart Color Scheme</h2>
        <p className="mb-4 text-gray-600">
          Our AI Text Highlighter uses a smart color scheme to help you categorize and differentiate key information in your study material. The system employs specific colors for different types of content, allowing you to focus effectively on what is most relevant.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2"><span className="text-pink-500">Pink</span> for main ideas</li>
          <li className="mb-2"><span className="text-green-500">Green</span> for important data</li>
          <li className="mb-2"><span className="text-blue-500">Blue</span> for definitions</li>
          <li className="mb-2"><span className="text-red-500">Red</span> for key dates</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">How It Works</h2>
        <p className="mb-4 text-gray-600">
          Using our AI Text Highlighter is very simple. You just need to upload your PDF document, images, or enter the text directly in our editor. The AI will process the content and apply the highlighting according to the predefined color scheme. This process allows you to quickly identify the most important parts of your study material.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Upload your document or text images</li>
          <li className="mb-2">Let the AI analyze and highlight the content</li>
          <li className="mb-2">Receive a highlighted document ready for review</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Benefits of AI Highlighting</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Time-saving for studying</li>
          <li className="mb-2">Improved information retention</li>
          <li className="mb-2">Quick identification of key points</li>
          <li className="mb-2">Increased review efficiency</li>
          <li className="mb-2">Reduced manual highlighting effort</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Applications of AI Text Highlighter</h2>
        <p className="mb-4 text-gray-600">
          The AI Text Highlighter is a versatile tool that can be used in various academic and professional contexts. Whether you are preparing for an exam, conducting research, or simply reviewing an important document, this tool allows you to focus on what is most relevant quickly and efficiently.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Students preparing for exams</li>
          <li className="mb-2">Professionals reviewing reports</li>
          <li className="mb-2">Researchers analyzing academic texts</li>
          <li className="mb-2">Anyone who needs to optimize their study process</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Enhance Your Study with Our AI Text Highlighter</h2>
        <p className="text-gray-600">
          Our AI Text Highlighter is designed to transform the way you study and process information. Take advantage of this innovative tool to identify and focus on the most important parts of your study material. Whether you are in school, university, or work, our AI highlighter will help you maximize your efficiency and effectiveness. Start

 using it today and discover a new way to study!
        </p>
      </section>
    </div>
  );
  
  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text={"Text Highlighter"} />
      <div className="container max-w-4xl mx-auto p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          📝 Text Highlighter
        </h1>
        <p>
          Upload a PDF document or multiple images, or you can enter text directly to get a highlighted version that helps you study.
        </p>
        <p className="mb-6 mt-2">
          The following color scheme is used: pink for main ideas, green for important data, blue for definitions, and red for key dates.
        </p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          Enter text or upload a PDF
        </h2>
        <div>
          {!file && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder="Write your text here..."
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              onChange={handleFileChange}
              className="input input-bordered w-full mt-4"
            />
          )}
        </div>

        <button onClick={handleHighlight} className="btn btn-primary mt-4">
          Highlight Text
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">Processing files...</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {highlightedText && (
          <div
            className="p-4 mt-4 rounded-box text-lg border border-base-300"
            dangerouslySetInnerHTML={{ __html: highlightedText }}
          ></div>
        )}
      </div>
      {!token && <SEOText />}
    </div>
  );
};

export default Highlight;
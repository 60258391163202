import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import stringSimilarity from "string-similarity";

const getEncouragementMessage = (score) => {
  if (score < 3) return "Keep trying!";
  if (score < 6) return "You're doing well!";
  if (score < 9) return "Excellent work!";
  return "You're a genius!";
};

const Quiz = ({ questions }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState({});
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [matchPairs, setMatchPairs] = useState([]);
  const [orderResponse, setOrderResponse] = useState([]);

  const handleAnswer = (answer) => {
    const correctAnswer = questions[currentQuestion].answer;
    let updatedScore = score;
    let isCorrect = false;

    if (questions[currentQuestion].type === "open_ended") {
      const similarity = stringSimilarity.compareTwoStrings(
        answer || "",
        correctAnswer || ""
      );
      isCorrect = similarity > 0.7; // Consider the answer correct if similarity is greater than 70%
    } else {
      isCorrect = JSON.stringify(answer) === JSON.stringify(correctAnswer);
    }

    if (isCorrect) {
      if (!questions[currentQuestion].answered) {
        updatedScore++;
        questions[currentQuestion].answered = true;
      }
      toast.success("Correct! 🎉");
    } else if (!isCorrect) {
      toast.error("Incorrect 😢");
    }

    setResponses({
      ...responses,
      [currentQuestion]: answer,
    });
    setScore(updatedScore);
  };

  const nextQuestion = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
    }
  };

  const prevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleReset = () => {
    setCurrentQuestion(0);
    setResponses({});
    setScore(0);
    setShowResults(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowResults(true);
  };

  const handleMatchChange = (left, right) => {
    const updatedPairs = matchPairs.filter((pair) => pair.left !== left);
    setMatchPairs([...updatedPairs, { left, right }]);
  };

  const handleOrderChange = (index, value) => {
    const updatedOrder = [...orderResponse];
    updatedOrder[index] = value;
    setOrderResponse(updatedOrder);
  };

  return (
    <div className="container mx-auto p-4 my-8">
      <Toaster />
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-4xl font-bold">PhotoExam Quiz</h1>
        <div className="badge badge-primary p-4">
          Points: {score} - {getEncouragementMessage(score)}
        </div>
      </div>
      {!showResults ? (
        <div>
          <div className="card shadow-lg compact bg-base-100 mb-4">
            <div className="card-body">
              <h2 className="card-title">
                {currentQuestion + 1 + ". "}
                {questions[currentQuestion].question}
              </h2>
              {questions[currentQuestion].type === "multiple_choice" ? (
                questions[currentQuestion].options.map((option, i) => (
                  <div key={i} className="form-control">
                    <label
                      className={`cursor-pointer label ${
                        responses[currentQuestion] === option
                          ? "text-green-500"
                          : ""
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name={`question-${currentQuestion}`}
                          value={option}
                          checked={responses[currentQuestion] === option}
                          onChange={() => handleAnswer(option)}
                          className="radio radio-primary"
                        />
                        <span className="label-text ml-8">{option}</span>
                      </div>
                    </label>
                  </div>
                ))
              ) : questions[currentQuestion].type === "true_false" ? (
                questions[currentQuestion].options.map((option, i) => (
                  <div key={i} className="form-control">
                    <label
                      className={`cursor-pointer label ${
                        responses[currentQuestion] === option
                          ? "text-green-500"
                          : ""
                      }`}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name={`question-${currentQuestion}`}
                          value={option}
                          checked={responses[currentQuestion] === option}
                          onChange={() => handleAnswer(option)}
                          className="radio radio-primary"
                        />
                        <span className="label-text ml-8">{option}</span>
                      </div>
                    </label>
                  </div>
                ))
              ) : questions[currentQuestion].type === "open_ended" ? (
                <div className="form-control">
                  <textarea
                    className="textarea textarea-bordered"
                    placeholder="Write your answer here..."
                    value={responses[currentQuestion] || ""}
                    onChange={(e) =>
                      setResponses({
                        ...responses,
                        [currentQuestion]: e.target.value,
                      })
                    }
                  />
                  <button
                    onClick={() => handleAnswer(responses[currentQuestion])}
                    className="btn btn-primary mt-2"
                  >
                    Submit
                  </button>
                </div>
              ) : questions[currentQuestion].type === "fill_in_the_blanks" ? (
                <div className="form-control">
                  <input
                    type="text"
                    className="input input-bordered"
                    placeholder="Write your answer here..."
                    value={responses[currentQuestion] || ""}
                    onChange={(e) =>
                      setResponses({
                        ...responses,
                        [currentQuestion]: e.target.value,
                      })
                    }
                  />
                  <button
                    onClick={() => handleAnswer(responses[currentQuestion])}
                    className="btn btn-primary mt-2"
                  >
                    Submit
                  </button>
                </div>
              ) : questions[currentQuestion].type === "matching" ? (
                <div className="form-control">
                  {questions[currentQuestion].pairs.map((pair, i) => (
                    <div key={i} className="mb-2">
                      <label className="label">
                        <span className="label-text">{pair.left}</span>
                        <select
                          className="select select-bordered"
                          onChange={(e) =>
                            handleMatchChange(pair.left, e.target.value)
                          }
                        >
                          <option value="">Select an option</option>
                          {questions[currentQuestion].pairs.map((p, j) => (
                            <option key={j} value={p.right}>
                              {p.right}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  ))}
                  <button
                    onClick={() => handleAnswer(matchPairs)}
                    className="btn btn-primary mt-2"
                  >
                    Submit
                  </button>
                </div>
              ) : questions[currentQuestion].type === "ordering" ? (
                <div className="form-control">
                  {questions[currentQuestion].options.map((option, i) => (
                    <div key={i} className="mb-2">
                      <label className="label">
                        <span className="label-text">{i + 1}</span>
                        <select
                          className="select select-bordered"
                          onChange={(e) => handleOrderChange(i, e.target.value)}
                        >
                          <option value="">Select an option</option>
                          {questions[currentQuestion].options.map((opt, j) => (
                            <option key={j} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  ))}
                  <button
                    onClick={() => handleAnswer(orderResponse)}
                    className="btn btn-primary mt-2"
                  >
                    Submit
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex justify-between">
            <button
              onClick={prevQuestion}
              className="btn btn-secondary"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={nextQuestion}
              className={`btn btn-secondary ${
                currentQuestion === questions.length - 1 ? "btn-success" : ""
              }`}
            >
              {currentQuestion === questions.length - 1
                ? "Finish"
                : "Next"}
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-8">
          <h2 className="text-3xl font-bold mb-4">Quiz Summary</h2>
          {questions.map((question, index) => (
            <div
              key={index}
              className="card shadow-lg compact bg-base-100 mb-4"
            >
              <div className="card-body">
                <h2
                  className={
                    JSON.stringify(responses[index]) ===
                      JSON.stringify(question.answer) ||
                    (question.type === "open_ended" &&
                      stringSimilarity.compareTwoStrings(
                        responses[index] || "",
                        question.answer || ""
                      ) > 0.7)
                      ? "card-title text-green-500"
                      : "card-title text-red-500"
                  }
                >
                  {index + 1 + ". "} {question.question}
                </h2>
                {question.type === "multiple_choice" ||
                question.type === "true_false" ? (
                  <p className="text-lg">
                    Your answer: {responses[index]}
                    <br />
                    Correct answer: {question.answer}
                  </p>
                ) : question.type === "matching" ? (
                  <div className="text-lg">
                    {responses[index] &&
                      responses[index].map((pair, i) => (
                        <div key={i}>
                          {pair.left} - {pair.right}
                        </div>
                      ))}
                    <br />
                    Correct answer:
                    {question.answer.map((pair, i) => (
                      <div key={i}>
                        {pair.left} - {pair.right}
                      </div>
                    ))}
                  </div>
                ) : question.type === "ordering" ? (
                  <div className="text-lg">
                    Your answer:
                    <ul>
                      {responses[index] &&
                        responses[index].map((item, i) => (
                          <li key={i}>{item}</li>
                        ))}
                    </ul>
                    Correct answer:
                    <ul>
                      {question.answer.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p className="text-lg">
                    Your answer: {responses[index]}
                    <br />
                    Suggested answer: {question.answer}
                  </p>
                )}
                {JSON.stringify(responses[index]) ===
                  JSON.stringify(question.answer) ||
                (question.type === "open_ended" &&
                  stringSimilarity.compareTwoStrings(
                    responses[index] || "",
                    question.answer || ""
                  ) > 0.7) ? (
                  <p className="text-green-500">Correct! 🎉</p>
                ) : (
                  <p className="text-red-500">Incorrect 😢</p>
                )}
              </div>
            </div>
          ))}
          <p className="text-xl font-bold">Final score: {score}</p>
          <button onClick={handleReset} className="btn btn-primary mt-4">
            Repeat Quiz
          </button>
        </div>
      )}
    </div>
  );
};

export default Quiz;

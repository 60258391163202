import React, { useState } from "react";
import ReactGA4 from "react-ga4";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "tailwindcss/tailwind.css";

import { useEffect } from "react";
import MarkmapSchemaCreator from "./components/ConceptualMaps";
import ExamCorrector from "./components/ExamCorrector";
import ExamList from "./components/ExamList";
import ExamSolver from "./components/ExamSolver";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Highlight from "./components/Highlight";
import QuizFile from "./components/QuizFile";
import Summary from "./components/Summary";
import { TutorsGallery } from "./components/TutorsGallery"; // Asegúrate de importar TutorsGallery
import UserProfile from "./components/UserProfile";
import NoteTaker from "./components/NoteTaker";
import useAuth from "./components/useAuthToken";
import ModalNoToken from "./components/ModalNoToken";
import Welcome from "./components/Welcome";




const TRACKING_ID = "G-6FQ7N7HHNB"; // Replace with your Google Analytics 4 tracking ID

const PageTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA4.initialize(TRACKING_ID);
    ReactGA4.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });

    if (localStorage.getItem("email")) {
      window.plausible("pageview", {
        props: {
          path: location.pathname + location.search,
          email: localStorage.getItem("email"),
        },
      });
    } else
      window.plausible("pageview", {
        props: { path: location.pathname + location.search },
      });
  }, [location]);

  useEffect(() => {
    const showModalAfterTimeout = () => {
      const modalShown = localStorage.getItem("modalShown");
      if (!modalShown) {
        setTimeout(() => {
          document.getElementById("my_modal_3").showModal();
          localStorage.setItem("modalShown", "true");
        }, 240000); // 4 minutos
      }
    };

    showModalAfterTimeout();
  }, []);

  return null; // This component does not render anything
};

function HandleNoToken({showNoTokenModal, setShowNoTokenModal}) {
  const [token, clearToken] = useAuth();
  useEffect(() => {
    console.log(showNoTokenModal, token);
    if (token === null && showNoTokenModal) {
      setShowNoTokenModal(true);
    }
  }, [showNoTokenModal, setShowNoTokenModal, token]);
  return (
    showNoTokenModal && (
      <ModalNoToken setShowNoTokenModal={setShowNoTokenModal} />
    )
  );
}

function App() {
  const [showNoTokenModal, setShowNoTokenModal] = useState(false);
  return (
    <Router>
      <HandleNoToken
        showNoTokenModal={showNoTokenModal}
        setShowNoTokenModal={setShowNoTokenModal}
      />
      <div className="App">
        <Header>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <PageTracker />
                <ExamSolver setShowNoTokenModal={setShowNoTokenModal}/>
              </>
            }
          />
           <Route
              path="/welcome"
              element={
                <>
                  <PageTracker />
                  <Welcome setShowNoTokenModal={setShowNoTokenModal}/>
                </>
              }
            />
          <Route
            path="/correct-exam-with-ai"
            element={
              <>
                <PageTracker />
                <ExamCorrector setShowNoTokenModal={setShowNoTokenModal}/>
              </>
            }
          />
          <Route
            path="/highlight-text-with-ai"
            element={
              <>
                <PageTracker />
                <Highlight setShowNoTokenModal={setShowNoTokenModal}/>
              </>
            }
          />
          <Route
            path="/create-summary-with-ai"
            element={
              <>
                <PageTracker />
                <Summary setShowNoTokenModal={setShowNoTokenModal}/>
              </>
            }
          />
          <Route
            path="/create-diagrams-with-ai"
            element={
              <>
                <PageTracker />
                <MarkmapSchemaCreator setShowNoTokenModal={setShowNoTokenModal}/>
              </>
            }
          />
          <Route
            path="/tutors-ai"
            element={
              <>
                <PageTracker />
                <TutorsGallery setShowNoTokenModal={setShowNoTokenModal}/>
              </>
            }
          />{" "}
          {/* Nueva ruta para TutorsGallery */}
          {/* {<Route path="/mapasconceptuales" element={<><PageTracker /><MarkmapSchemaCreator /></>} /> } */}
          <Route
            path="/history"
            element={
              <>
                <PageTracker />
                <ExamList userEmail="user@example.com" />
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <>
                <PageTracker />
                <UserProfile setShowNoTokenModal={setShowNoTokenModal} />
              </>
            }
          />
          <Route
            path="/interactive-quiz-ai"
            element={
              <>
                <PageTracker />
                <QuizFile setShowNoTokenModal={setShowNoTokenModal}/>
              </>
            }
          />
          <Route
              path="/note-taking-with-ai"
              element={
                <>
                  <PageTracker />
                  <NoteTaker />
                </>
              }
            />
          <Route
            path="*"
            element={
              <>
                <PageTracker />
                <h1 className="text-center mt-10 text-2xl font-bold">
                  Página no encontrada
                </h1>
              </>
            }
          />
        </Routes>
        <Footer />
        </Header>
      </div>
    </Router>
  );
}

export default App;
